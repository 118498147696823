<template>
  <div ref="elementRef" class="group relative overflow-hidden rounded-lg" @click="handlePromotionClick">
    <base-picture
      v-if="responsiveMedia.images"
      v-bind="responsiveMedia.images"
      :lazy
      fit="cover"
      class="w-full duration-800 group-hover:scale-105"
    />
    <base-video
      v-else-if="responsiveMedia.video"
      v-bind="video"
      ref="videoRef"
      :autoplay="!lazy"
      :controls="false"
      loop
      muted
      class="w-full cover"
    />
    <div
      class="absolute-0"
      style="background: linear-gradient(180deg, rgba(0, 0, 0, 0) 60%, #000000 100%);"
    />
    <div class="absolute-0 flex items-end">
      <div class="w-full items-center p-4 c-white lg:p-6">
        <base-link
          v-if="title"
          :to="mediaLink?.url"
          :target="mediaLink?.targetAttribute"
          class="elevate-body-1"
          data-test-id="cms-linked-card-title"
        >
          {{ title }}
          <span class="absolute-0" />
        </base-link>
        <vf-icon name="arrow" size="lg" class="pointer-events-none" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { SectionContextKey } from '#content/components/cms/section/context'
import type { LinkedCardContent } from '#types/components/cms/linked-card'
import type { BaseVideo as BaseVideoPropType } from '#types/components/base/video'

const { content } = defineProps<{
  content: LinkedCardContent
  isPartOfCollection?: boolean
}>()

const {
  media,
  mediaTarget,
  promotionTracking,
  targets = [],
  title
} = content

const { getVideo, getMedia } = useCms()
const {
  elementRef,
  handlePromotionClick
} = usePromotionTracking(promotionTracking, inject(SectionContextKey), content.name)

const lazy = inject(LazyMedia)
const mediaLink = mediaTarget || targets[0]
const responsiveMedia = getMedia(media)
const video = ref<BaseVideoPropType>({ src: '' })
const videoRef = ref<{ $el: HTMLVideoElement }>()

onMounted(() => {
  if ('video' in responsiveMedia) {
    video.value = getVideo(responsiveMedia.video) as BaseVideoPropType
    if (videoRef.value) {
      videoRef.value.$el.load()
      videoRef.value.$el.play()
    }
  }
})
</script>
